<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('menu.intereses')}` }}
        </v-card-title>
        <v-card-text>
          <InteresesList />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InteresesList from './form/InteresesList.vue'

export default {
  components: {
    InteresesList,
  },
}
</script>
